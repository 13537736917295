import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout";
const imageBaconBrunch = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-brunch-burger-recipe-1367x737px.jpg';

const RecipePage = () => (
    <Layout metaTitle="Bacon Brunch Burger Recipe"
        metaDescription="Indulge in the ultimate Bacon Brunch Burger
        with our easy recipe. A perfect blend of
        breakfast and lunch flavors in a juicy burger.
        Get grilling today!"
        metaKeywords=""
    >
        <meta property="og:image" content={imageBaconBrunch} />
        <link rel="image_src" href={imageBaconBrunch} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconBrunch} class='img-responsive' alt="The Wright Brand Bacon Brunch" />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Brunch Burger</h1>

                            <div class="recipe-content">
                                <p >Redefining brunch the only way we know how. Thick-cut.</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        15&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        45&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>4</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                12 strips of Wright® Brand, Thick-Cut Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 pound ground beef
                              
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                               4 buns, toasted
                               
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                              4 large eggs
                              
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                              2 avocados
                               
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                              2 tablespoons lime juice
                              
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                              1 stick salted butter
                                 
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat convetional oven to 400F.

                </p>
                            <p>
                                2. Place the bacon on a foil lined sheet pan in a single layer, cook for 18 - 20 mins or until cooked to desired crispness.
                </p>
                            <p>
                                3. Remove bacon from oven & place on paper towels to drain excess grease. Set aside for later use.
                </p>
                            <p>
                                4. While bacon in cooking, take avocados and halve, pit & peel into a small bowl. Mash with fork adding lime juice, salt & pepper. Set aside.
                </p>
                           <p>
                                5. Outside, fire up your grill to a medium heat. 
                </p>
                           <p>
                                6. Shape the ground beef into 4 patties, each about 1-1 ½ inch thick. Grill the burgers for 6-10 minutes per side or until an internal temperature of 145-165°F is reached and juices run clear. Set aside.
                </p>
                           <p>
                                7. Inside, heat butter in a skillet on the stove. Fry eggs in skillet to over easy. Salt & pepper eggs. Set aside on paper towels to drain excess butter.

                </p>
                           <p>
                                8. Use excess butter in skillet to toast your buns. Repeat adding additional butter if needed until all buns are toasted.
                </p>       <p>
                                9. Build your burger: Place ¼ of avocado spread on bottom of bun, 1 beef patty, 1 egg, 3 slices of bacon & top bun. Repeat for all 4 burgers.
                </p>
                        </div>

                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
